import { Component, OnInit } from "@angular/core";
import { CompanyService } from "../../services/company/company.service";
import { CompanyFocusService } from "../../services/companyOnFocus/company-focus.service";
import { AuthService } from "../../services/authFirebase/auth.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/users/user.service";
import { formatDate } from "@angular/common";
import { NbDialogService } from "@nebular/theme";
import { MissingCallsComponent } from "./missing-calls/missing-calls.component";
import Swal from "sweetalert2";
import { Company } from "src/app/models/company";
import { UserData } from "src/app/models/user";
import { PendingsService } from "src/app/services/pendings/pendings.service";
import { Pending } from "src/app/models/pending";
import { CallsReportsService } from "src/app/services/calls-reports/calls-reports.service";
import { ContractsService } from "src/app/services/contracts/contracts.service";
import { ProspectsService } from "src/app/services/prospects/prospects.service";
import { Prospect } from "src/app/models/prospects";
import { ZoomService } from "src/app/services/zoom/zoom.service";
import { EditZoomComponent } from "src/app/components/edit-zoom/edit-zoom.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {

  public today = new Date();
  public missingCalls = [];
  public myClients: Prospect[] = [];
  public allClients: Company[] = [];
  public backupClients: Prospect[] = [];
  public nextClients: Prospect[] = [];
  public term = '';
  public loader = false
  public userData: any;
  public pendingList: Pending[] = []
  public contractList = []
  public prospectList = []
  public nextMeets = []

  constructor(
    private router: Router,
    private authservice: AuthService,
    private dialogService: MatDialog,
    private focusCompany: CompanyFocusService,
    private userService: UserService,
    private pendingService: PendingsService,
    private callService: CallsReportsService,
    private contractService: ContractsService,
    private prospectService: ProspectsService,
    private zoomService: ZoomService
  ) {
    this.userData = localStorage.getItem("currentUser");
  }

  async ngOnInit() {
    this.loader = true
    this.userData = await this.getUserData();
    this.focusCompany.dissmissFocus();
    this.pendingList = await this.getPendings()
    this.contractList = await this.getAllContracts()
    if (this.userData.tipo != 'marketing') {
      // this.allClients = await this.getClients()
      this.prospectList = await this.getProspects()
      this.myClients = await this.getMyClients(this.prospectList, this.userData.nombre)
      this.backupClients = await this.getBackupsClietns(this.prospectList, this.userData.nombre)
      this.nextClients = await this.getNextProspects()
      this.nextMeets = await this.getMeetZoom(this.userData.uid)
    }
    this.loader = false;
  }

  selectCompany(company) {
    this.focusCompany.setFocusCompany(company.uid);
    this.router.navigate(["diary/" + company.uid], company);
  }

  async getProspects() {
    try {
      var response = await this.prospectService.getActiveProspects()
      response.data.map((prospect: any) => {
        var pendingsOfUser = this.pendingList.filter((pending) => pending.clientId == prospect.uid)
        if (pendingsOfUser.length > 0) {
          prospect['totalPending'] = pendingsOfUser.length
        }
        var clientContract = this.contractList.filter((contract) => contract.clientId == prospect.uid)
        if (clientContract.length != 0) {
          prospect['contractInfo'] = clientContract[0]
        }
        prospect.nombre = prospect.nombre.toUpperCase();
      })
      return response.data.sort((a: any, b: any) => a.contractInfo.status - b.contractInfo.status)
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al consultar los prospectos',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getMeetZoom(id: string) {
    try {
      const response = await this.zoomService.findMeetOfUser(id)
      var zoomToday = response.filter((zoom) => new Date(zoom.meetDate) >= new Date(new Date().setHours(0, 0, 0, 0)) && new Date(zoom.meetDate) <= new Date(new Date().setDate(new Date().getDate() + 1)))
      zoomToday.map((zoomInfo) => {
        zoomInfo["client"] = this.prospectList.filter((prospect) => prospect._id == zoomInfo.clientId)[0].nombre
      })
      return zoomToday
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar las conferencias',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getBackupsClietns(allClients: Prospect[], userName: string) {
    try {
      var myClients = allClients.filter((companyInfo) => companyInfo.assistant && companyInfo.assistant.includes(userName))
      return myClients
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al consultar los prospectos backup',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getNextProspects() {
    try {
      var response = await this.prospectService.getAllProspects()
      response.data.map((prospect) => {
        if (prospect.trainingHour) {
          prospect.trainingHour = this.getTime(prospect.trainingHour)
        }
      })
      return response.data.filter((prospect) => prospect.ejecutivo.includes(this.userData.nombre))
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al consultar los prospectos backup',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getPendings() {
    try {
      const pendingList = await this.pendingService.getPendings()
      if (pendingList.code == 200) {
        return pendingList.data
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar los pendientes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getAllContracts() {
    try {
      const response = await this.contractService.get()
      return response.data
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar los contratos',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getUserData() {
    try {
      let currentUserId = this.authservice.currentuser().uid;
      return await this.userService.getUserById(currentUserId);
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al consultar la informacion del usuario',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getMyClients(allClients: Prospect[], userName: string) {
    try {
      var myClients = allClients.filter((companyInfo) => companyInfo.teamLeader && companyInfo.teamLeader.includes(userName) || companyInfo.ejecutivo.includes(userName))
      return myClients
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al consultar los clientes del usuario',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async showMissingCalls(company) {
    Swal.fire({
      title: "Cargando...",
      icon: "info",
    });

    Swal.showLoading();

    this.missingCalls = [];
    var startDateSearch = formatDate(
      this.today.setDate(this.today.getDate() - 1),
      "dd/MM/yyyy",
      "en_US"
    );
    var endDateSearch = formatDate(
      this.today.setDate(this.today.getDate() + 1),
      "dd/MM/yyyy",
      "en_US"
    );
    var phone = company.telefono.toString();
    var phoneSearch = phone.includes("52") ? phone : `52${company.telefono}`;

    try {

      let missingCalls = await this.callService.getMissingCalls(phoneSearch, startDateSearch, endDateSearch);
      var filterCallsMap = new Map();

      await Promise.all(
        missingCalls.map((element) => {
          let hour = parseInt(element.calldate.split(" ")[1].split(":")[0]);
          element.minutes = this.secondsToHms(element.duration);

          if (hour >= 9 && hour <= 18) {
            element.horario = "Llamada dentro de horario";
          } else {
            element.horario = "Llamada fuera de horario";
          }
          if (element.duration >= 15) {
            if (filterCallsMap.size == 0) {
              filterCallsMap.set(element.uniqueid, {
                information: element,
              });
            } else {
              if (!filterCallsMap.has(element.uniqueid)) {
                filterCallsMap.set(element.uniqueid, {
                  information: element,
                });
              }
            }

          }

        })
      );

      this.missingCalls = Array.from(filterCallsMap, ([id, value]) => ({
        id,
        value,
      }));

      Swal.close();
      this.dialogService.open(MissingCallsComponent, {
        data: { calls: this.missingCalls, clientName: company.nombre },height:'50vh'
      });

    } catch (error) {
      if (error.status == 504) {
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      }
      console.log(error);
    }
  }

  async updateZoom(event: any) {
    var result = await this.dialogService.open(EditZoomComponent, { data: event, height: '70vh' }).afterClosed().toPromise()
    if (result) {
      this.nextMeets = await this.getMeetZoom(this.userData.uid)
    }
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  selectCompanyChat(idCompany: string) {
    this.router.navigate(["chat/" + idCompany]);
  }

  getTime(timeRange: any): any {
    var hour = 0
    var minutes = '0'
    var meridian = ""
    if (timeRange.hour > 12) {
      meridian = 'p.m'
      hour = timeRange.hour - 12
    } else {
      meridian = 'a.m'
      hour = timeRange.hour
    }
    if (timeRange.minute < 10) {
      minutes = '0' + timeRange.minute
    } else {
      minutes = timeRange.minute
    }
    return hour + ":" + minutes + " " + meridian
  }
}
