import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../authFirebase/auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class InComingCallService {

    inComingCallsRef: AngularFirestoreCollection<any>;
    iCCDoc: AngularFirestoreDocument;
    userDoc: AngularFirestoreDocument;
    inComingCalls$: Observable<any>;
    iCCDoc$: Observable<any>;
    user$: Observable<any>;

    //API
    urlPost: string = "http://157.230.172.247:9001/application/calls"
    subscription: Subscription;
    constructor(private afs: AngularFirestore,
        public auth: AuthService,
        private http: HttpClient
    ) { }

    getInComingCalls(ext) {
        this.inComingCallsRef = this.afs.collection('incomingCalls', ref => ref.where("extension", "==", ext).orderBy('date', 'desc').limit(1));
        this.inComingCalls$ = this.inComingCallsRef.snapshotChanges().map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data() as any;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
        });
        return this.inComingCalls$;
    }

    async deleteICC(id) {
        localStorage.setItem('isCallOnFocus', 'false');
        localStorage.removeItem("iCCId");
        localStorage.removeItem("telefonoContact");
        // console.log(id);
        // let db = this.afs;
        // let userId = this.auth.currentuser().uid;
        // let companyId = this.companyOnFocusService.getCompanyOnFocus();
        // let sub = this.subscription;
        // let user$ = this.user$;
        // let userDoc = this.userDoc;



        // this.afs.collection('incomingCalls').doc(id).delete().then(function (data) {
        //     userDoc = db.collection('usuarios').doc(userId);
        //     user$ = userDoc.valueChanges();
        //     sub = user$.subscribe(data => {
        //         db.collection('empresas').doc(companyId).collection('log').add({
        //             text: 'Llamada atendida por ' + data.nombre,
        //             textClient: 'Llamada contestada por tu asistente.',
        //             date: new Date(),
        //             idUser: data.id,
        //             automated: true,
        //             comments: 'n/a'
        //         }).then(function (data) {
        //             localStorage.setItem('isCallOnFocus', 'false');
        //             localStorage.removeItem("iCCId");
        //             localStorage.removeItem("telefonoContact");
        //             sub.unsubscribe();
        //         })
        //     })
        // })
    }


    async removeIncomingCall(docId) {
        try {
            this.afs.collection('incomingCalls').doc(docId).delete();
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async lostICC(currentCallInfo, companyInfo) {
        try {
            this.afs.collection('empresas').doc(companyInfo.uid).collection('lostCalls').doc(currentCallInfo.id).set({
                nombre: currentCallInfo.nombre,
                numero_origen: currentCallInfo.numero_origen,
                numero_destino: currentCallInfo.numero_destino,
                email: currentCallInfo.email,
                extension: currentCallInfo.extension,
                date: new Date(),
                id: currentCallInfo.id
            });
        } catch (error) {
            throw error
        }
    }

    dissmissFocusCall(idICC) {
        localStorage.setItem('isCallOnFocus', 'false');
        this.deleteICC(idICC);
    }

    getLostCallsByApi(request) {
        return this.http.post<any>(this.urlPost, request)
    }
}
