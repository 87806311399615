import { BrowserModule } from '@angular/platform-browser';
import { NgModule, forwardRef } from '@angular/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule, NbLayoutModule, NbActionsModule, NbContextMenuModule,
  NbMenuModule, NbUserModule, NbCardModule, NbButtonModule, NbIconModule,
  NbDialogModule, NbInputModule, NbSelectModule, NbTooltipModule, NbAccordionModule,
  NbDatepickerModule, NbToggleModule, NbListModule, NbTabsetModule, NbBadgeModule, NbCheckboxModule, NbSpinnerModule, NbFormFieldModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { environment } from "../environments/environment";
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { CompaniesComponent } from './pages/companies/companies.component';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 200,
  url: 'https://httpbin.org/post',
};

import { NumberDirective } from "./valid-number.directive";
import { NgxPrintModule } from 'ngx-print';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersComponent } from './pages/users/users.component';
import { LoginComponent } from './pages/login/login.component';
import { EditUserComponent } from './pages/users/user-form/edit.component';
import { CompanyFormComponent } from './components/companies/company-form/edit.component';
import { DiaryComponent } from './pages/diary/diary.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { registerLocaleData } from '@angular/common';
import localeMX from '@angular/common/locales/es-MX';
import { AddRecadoComponent } from './pages/diary/add-recado/add-recado.component';
import { AddInfoComponent } from './pages/diary/add-info/add-info.component';
import { SendMailComponent } from './pages/diary/send-mail/send-mail.component';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { CitaInfoComponent } from './pages/diary/cita-info/cita-info.component';
import { NgbModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ReporteInternoComponent } from './pages/reporte-interno/reporte-interno.component';
import { ReporteInternoDetailsComponent } from './pages/reporte-interno/details/details.component';
import { AddLogComponent } from './pages/diary/add-log/add-log.component';
import { GenerateReporteComponent } from './pages/reportes/generate/generate.component';
import { GenerateReporteInternoComponent } from "./pages/reporte-interno/generate/generate.component";
import { DetailsReportsComponent } from './pages/reportes/details/details.component';
import { ChatComponent } from './pages/chat/chat.component';
import { DatesLogsComponent } from './pages/companies/dates-logs/dates-logs.component';
import { ModalEventComponent } from './components/modal-event/modal-event.component'
registerLocaleData(localeMX);

//MATERIAL
import { MaterialModule } from './angular/material.module';

import { AddSpacesComponent } from './components/add-spaces/add-spaces.component';
import { DeleteSpacesComponent } from './components/delete-spaces/delete-spaces.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { PendingsListComponent } from './components/pendings-list/pendings-list.component';
import { AddPendingComponent } from './components/add-pending/add-pending.component';
import { ReScheduleComponent } from './components/re-schedule/re-schedule.component';
import { MissingCallsComponent } from './pages/dashboard/missing-calls/missing-calls.component';
import { GeneralReportsComponent } from './pages/general-reports/general-reports/general-reports.component';
import { GenerateGeneralReportComponent } from './pages/general-reports/generate-general-report/generate-general-report.component';
import { GeneralReportPageComponent } from './pages/general-reports/general-report-page/general-report-page.component';
import { CallReportsComponent } from './pages/call-reports/call-reports/call-reports.component';
import { ReporteRentabilidadComponent } from './pages/reporte-rentabilidad/reporte-rentabilidad.component';
import { ReporteRentabilidadModalComponent } from './pages/reporte-rentabilidad/reporte-rentabilidad-modal/reporte-rentabilidad-modal.component';
import { ReporteRentabilidadDetailsComponent } from './pages/reporte-rentabilidad/reporte-rentabilidad-details/reporte-rentabilidad-details.component';
import { ReporteAgentesComponent } from './pages/reporte-agentes/reporte-agentes.component';
import { GenerateReportComponent } from './pages/reporte-agentes/generate-report/generate-report.component';
import { ReporteAgentesDetailsComponent } from './pages/reporte-agentes/reporte-agentes-details/reporte-agentes-details.component';
import { AddPlanComponent } from './components/companies/plan-form/add-plan.component';
import { ScheduleComponent } from './pages/diary/schedule/schedule.component';
import { RecadosComponent } from './pages/diary/recados/recados.component';
import { InfoClientComponent } from './pages/diary/info-client/info-client.component';
import { BitacoraComponent } from './pages/diary/bitacora/bitacora.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ImportContactsComponent } from './pages/diary/import-contacts/import-contacts.component';
import { FilterClientsPipe } from './pipes/filter-clients.pipe';
import { PendingsUsersComponent } from './pages/pendings-users/pendings-users.component';
import { AddPendingsUsersComponent } from './components/add-pendings-users/add-pendings-users.component';
import { EditPendingUsersComponent } from './components/edit-pending-users/edit-pending-users.component';
import { FilterDocsPipe } from './pipes/filter-docs.pipe';
import { TaskFormComponent } from './components/task-form/add-task.component';
import { EditTimetaskComponent } from './components/edit-timetask/edit-timetask.component';
import { AddAditionalNumbersComponent } from './components/add-aditional-numbers/add-aditional-numbers.component';
import { PlansTableComponent } from './components/companies/plans-table/plans-table.component';
import { ClientsTableComponent } from './components/companies/clients-table/clients-table.component';
import { TasksTableComponent } from './components/companies/tasks-table/tasks-table.component';
import { UsersTableComponent } from './components/companies/users-table/users-table.component';
import { ToastsContainer } from './components/toast-notification/toasts-container.component';
import { ClientsComponent } from './components/diary/clients/clients.component';
import { AddClientComponent } from './components/diary/add-client/add-client.component';
import { PatientsComponent } from './components/diary/patients/patients.component';
import { PatientsPipe } from './pipes/patients.pipe';
import { AddMeetComponent } from './components/diary/add-meet/add-meet.component';
import { PatientDatesTableComponent } from './components/diary/patients/patient-dates-table/patient-dates-table.component';
import { PatientsExpedientComponent } from './components/diary/patients/patients-expedient/patients-expedient.component';
import { MarketingDashboardComponent } from './pages/marketing-dashboard/marketing-dashboard.component';
import { AddProspectsComponent } from './components/companies/add-prospects/add-prospects.component';
import { CompleteProspectsComponent } from './components/companies/complete-prospects/complete-prospects.component';
import { CompleteProspectTableComponent } from './components/marketing/complete-prospect-table/complete-prospect-table.component';
import { CancelProspectTableComponent } from './components/marketing/cancel-prospect-table/cancel-prospect-table.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { ChartsModule } from 'ng2-charts';
import { DriveManagerComponent } from './pages/drive-manager/drive-manager.component';
import { DriveViewComponent } from './pages/drive-manager/drive-view/drive-view.component';
import { AddFilesComponent } from './components/marketing/add-files/add-files.component';
import { UserScheduleComponent } from './pages/user-schedule/user-schedule.component';
import { ProspectEventDialogComponent } from './components/prospect-event-dialog/prospect-event-dialog.component';
import { ChangeTimeComponent } from './components/change-time/change-time.component';
import { ProfibilityReportComponent } from './pages/profibility-report/profibility-report.component';
import { GenerateReportProfibilityComponent } from './pages/profibility-report/generate-report/generate-report.component';
import { ReportViewComponent } from './pages/profibility-report/report-view/report-view.component';
import { AdminMarketingComponent } from './pages/admin-marketing/admin-marketing.component';
import { AnalyticsClientsComponent } from './pages/analytics-clients/analytics-clients.component';
import { ViewProspectInfoComponent } from './components/marketing/view-prospect-info/view-prospect-info.component';
import { EditRowsTableComponent } from './components/marketing/edit-rows-table/edit-rows-table.component';
import { EditRowsProspectTableComponent } from './components/marketing/edit-rows-prospect-table/edit-rows-prospect-table.component';
import { AddAditionalInfoComponent } from './components/marketing/add-aditional-info/add-aditional-info.component';
import { AddFileProspectComponent } from './components/marketing/add-file-prospect/add-file-prospect.component';
import { PendingTimeTableComponent } from './components/pending-time-table/pending-time-table.component';
import { PendingConcludedTableComponent } from './components/pending-concluded-table/pending-concluded-table.component';
import { ClientsContractComponent } from './components/companies/clients-contract/clients-contract.component';
import { CallModalComponent } from './components/call-modal/call-modal.component';
import { ContractFormComponent } from './components/contract-form/contract-form.component';
import { GenerateZoomComponent } from './components/generate-zoom/generate-zoom.component';
import { ProfileUserComponent } from './pages/profile-user/profile-user.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { EditZoomComponent } from './components/edit-zoom/edit-zoom.component';
import { ReportRankingComponent } from './pages/report-ranking/report-ranking.component';
import { ReportPageComponent } from './pages/report-ranking/report-page/report-page.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CompaniesComponent,
    UsersComponent,
    LoginComponent,
    EditUserComponent,
    CompanyFormComponent,
    DiaryComponent,
    AddRecadoComponent,
    AddInfoComponent,
    SendMailComponent,
    ReportesComponent,
    CitaInfoComponent,
    ReporteInternoComponent,
    ReporteInternoDetailsComponent,
    AddLogComponent,
    GenerateReporteComponent,
    GenerateReporteInternoComponent,
    DetailsReportsComponent,
    NumberDirective,
    ChatComponent,
    DatesLogsComponent,
    ModalEventComponent,
    AddSpacesComponent,
    DeleteSpacesComponent,
    NotificationsComponent,
    PendingsListComponent,
    AddPendingComponent,
    ChangeTimeComponent,
    ReScheduleComponent,
    MissingCallsComponent,
    GeneralReportsComponent,
    GenerateGeneralReportComponent,
    ToastsContainer,
    GeneralReportPageComponent,
    CallReportsComponent,
    ReporteRentabilidadComponent,
    ReporteRentabilidadModalComponent,
    ReporteRentabilidadDetailsComponent,
    ReporteAgentesComponent,
    GenerateReportComponent,
    ReporteAgentesDetailsComponent,
    AddPlanComponent,
    ScheduleComponent,
    RecadosComponent,
    InfoClientComponent,
    BitacoraComponent,
    FilterPipe,
    ImportContactsComponent,
    FilterClientsPipe,
    PendingsUsersComponent,
    AddPendingsUsersComponent,
    EditPendingUsersComponent,
    FilterDocsPipe,
    TaskFormComponent,
    EditTimetaskComponent,
    AddAditionalNumbersComponent,
    PlansTableComponent,
    ClientsTableComponent,
    TasksTableComponent,
    UsersTableComponent,
    ClientsComponent,
    AddClientComponent,
    PatientsComponent,
    PatientsPipe,
    AddMeetComponent,
    PatientDatesTableComponent,
    PatientsExpedientComponent,
    MarketingDashboardComponent,
    AddProspectsComponent,
    CompleteProspectsComponent,
    CompleteProspectTableComponent,
    CancelProspectTableComponent,
    AnalyticsComponent,
    DriveManagerComponent,
    DriveViewComponent,
    AddFilesComponent,
    UserScheduleComponent,
    ProspectEventDialogComponent,
    ProfibilityReportComponent,
    GenerateReportProfibilityComponent,
    ReportViewComponent,
    AdminMarketingComponent,
    AnalyticsClientsComponent,
    ViewProspectInfoComponent,
    EditRowsTableComponent,
    EditRowsProspectTableComponent,
    AddAditionalInfoComponent,
    AddFileProspectComponent,
    PendingTimeTableComponent,
    PendingConcludedTableComponent,
    ClientsContractComponent,
    CallModalComponent,
    ContractFormComponent,
    GenerateZoomComponent,
    ProfileUserComponent,
    ColorPickerComponent,
    EditZoomComponent,
    ReportRankingComponent,
    ReportPageComponent,
  ],
  entryComponents: [
    EditUserComponent,
    CompanyFormComponent,
    AddRecadoComponent,
    AddInfoComponent,
    SendMailComponent,
    CitaInfoComponent,
    AddLogComponent,
    GenerateReporteComponent,
    GenerateReporteInternoComponent,
    DetailsReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbActionsModule,
    NbEvaIconsModule,
    NbIconModule,
    NbContextMenuModule,
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbUserModule,
    NbButtonModule,
    NbBadgeModule,
    NbCardModule,
    NbSelectModule,
    NbToggleModule,
    NbTooltipModule,
    NbListModule,
    NbAccordionModule,
    NbInputModule,
    NbDatepickerModule.forRoot(),
    AngularFireModule.initializeApp(environment.config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    DropzoneModule,
    HttpClientModule,
    NgbModule,
    NgbTimepickerModule,
    FormsModule,
    NbTabsetModule,
    NbCheckboxModule,
    NbSelectModule,
    NbSpinnerModule,
    NbFormFieldModule,
    ChartsModule,
    MaterialModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-MX" },
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AppComponent)
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
