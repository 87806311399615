import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'app-missing-calls',
    templateUrl: './missing-calls.component.html',
    styleUrls: ['./missing-calls.component.scss']
})
export class MissingCallsComponent implements OnInit {

    [x: string]: any;
    public clientName: string;
    public calls: Array<any> = [];
    constructor(
        public dialog: MatDialogRef<MissingCallsComponent>,
        @Inject(MAT_DIALOG_DATA) public ref: any
    ) { }

    ngOnInit() {
        this.calls = this.ref.calls;
        this.clientName = this.ref.clientName;
    }

}
