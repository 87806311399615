import { Injectable } from '@angular/core';
import { CompanyFocusService } from '../companyOnFocus/company-focus.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Contact } from 'src/app/models/contact';
import { Expedient } from 'src/app/models/expedient';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  public URIconnect = environment.URL_API;

  constructor(
    private companyOnFocusService: CompanyFocusService,
    public http: HttpClient,
    private afs: AngularFirestore,
  ) { }

  async addContact(data, idCompany) {
    try {
      let response = await this.http.post<any>(this.URIconnect + "/empresa/add-contact", { id: idCompany, numero: data }).toPromise();
      return
    } catch (error) {
      throw error
    }
  }

  async updateContact(id: any, data) {
    try {
      await this.http.post<any>(this.URIconnect + "/empresa/update-contact", { id: id, data: data, companyId: this.companyOnFocusService.getCompanyOnFocus() }).toPromise();
    } catch (error) {
      throw error
    }
  }

  async deleteContact(companyId: string, id: string) {
    try {
      await this.http.post<any>(this.URIconnect + "/empresa/delete-contact", { id: id, companyId: companyId }).toPromise();
    } catch (error) {
      throw error
    }
  }

  async getContact(phone, idCompany) {
    try {
      let response = await this.http.post<{data:any[]}>(this.URIconnect + "/empresa/find-contact", { id: idCompany, telefono: phone }).toPromise();
      if (response.data.length != 0) {
        return response.data
      }
      else {
        return []
      }
    } catch (error) {
      throw error
    }
  }

  async getContacsByCompany(id: string) {
    try {
      let response = await this.http.post<{ code: number, message: string, data: Contact[] }>(this.URIconnect + "/empresa/contactos", { id: id }).toPromise();
      return response.data

    } catch (error) {
      throw error
    }
  }

  async importContacts(json: any) {
    try {
      var response = await this.http.post<any>(this.URIconnect + "/empresa/importar", { data: json, companyId: this.companyOnFocusService.getCompanyOnFocus() }).toPromise();
      return response
    } catch (error) {
      throw error
    }
  }

  async updatePatient(companyId: string, contactId: string, form: any) {
    try {
      await this.afs.collection("empresas").doc(companyId).collection("contactos").doc(contactId).update(form)
    } catch (error) {
      throw error
    }
  }

  async addExpedient(expedient: any) {
    try {
      await this.http.post<any>(this.URIconnect + "/patient/add-expedient", { data: expedient }).toPromise();
    } catch (error) {
      throw error
    }
  }

  async getExpedientOfPatient(patientId: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Expedient[] }>(this.URIconnect + "/patient/get-expedient/" + patientId).toPromise();
      return response.data
    } catch (error) {
      throw error
    }
  }

  async deleteExpedient(expedientId:string){
    try {
      await this.http.delete<{ code: number, message: string, data: Expedient[] }>(this.URIconnect + "/patient/delete-expedient/" + expedientId).toPromise();
    } catch (error) {
      throw error
    }
  }

}
