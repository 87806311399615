import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CallsReportsService {

    public ulrConnect = environment.URL_API
    constructor(
        private afs: AngularFirestore,
        private http: HttpClient
    ) { }

    async updateCallsReports(data) {
        try {
            await this.http.put<{ code: number, message: string }>(this.ulrConnect + "/calls/update-array", { data }).toPromise()
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getCallsReports() {
        try {
            let response = await this.http.get<{ code: number, data: any }>(this.ulrConnect + "/calls/get-report").toPromise()
            return response.data
        } catch (error) {
            throw error;
        }
    }

    async addLog(companyId, data) {
        try {
            await this.http.post<{ code: number, message: string }>(this.ulrConnect + "/calls/add-log/" + companyId, { data }).toPromise()
            return true
        } catch (error) {
            throw error;
        }
    }

    async getMissingCallsByPhoneClient(phone: number) {
        try {
            var response = await this.http.get<{ code: number, data: any }>(this.ulrConnect + "/calls/get-missing/" + phone).toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    async attendMissingCall(callId: string) {
        try {
            await this.http.put<{ code: number, message: string }>(this.ulrConnect + "/calls/attend", { callId }).toPromise()
            return true
        } catch (error) {
            throw error
        }
    }

    async getMissingCall() {
        try {
            var response = await this.http.get<{ code: number, message: string, data: any }>(this.ulrConnect + "/calls/get-all").toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getMissingCalls(did, dateIni, dateEnd) {
        try {
            const answer = await this.http.post<{ code: number, data: any }>(this.ulrConnect + "/calls/missing", { "did": did, "dateIni": dateIni, "dateEnd": dateEnd }).toPromise();
            return answer.data;
        } catch (error) {
            throw error;
        }
    }

    async getCallSalientes(prefix, dateIni, dateEnd) {
        try {
            const answer = await this.http.post<{ code: number, data: any }>(this.ulrConnect + "/calls/getWithPrefix", { "prefix": prefix, "dateIni": dateIni, "dateEnd": dateEnd }).toPromise();
            return answer.data;
        } catch (error) {
            throw error;
        }
    }

    async getAllCalls(extension: string, startDate: string, endDate: string) {
        try {
            const answer = await this.http.get<{ code: number, data: any }>(this.ulrConnect + "/calls/" + extension + "/" + startDate + "/" + endDate).toPromise();
            return answer.data
        } catch (error) {
            throw error;
        }
    }

    async getOutgoinCalls(prefix: string, dateIni: number, dateEnd: number) {
        try {
            const answer = await this.http.get<{ code: number, data: any }>(this.ulrConnect + "/calls/outgoing/" + prefix + "/" + dateIni + "/" + dateEnd).toPromise();
            return answer.data;
        } catch (error) {
            throw error;
        }
    }

    observerMissingCalls() {
        try {
            return this.afs.collection('calls-reports').snapshotChanges().map(call => {
                return true
            })
        } catch (error) {
            throw error
        }
    }
}
