import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-rows-prospect-table',
  templateUrl: './edit-rows-prospect-table.component.html',
  styleUrls: ['./edit-rows-prospect-table.component.scss']
})
export class EditRowsProspectTableComponent implements OnInit {
  public displayColumns: any[] = [
    { controlName: "name", value: true, diplayLabel: "Nombre comercial" },
    { controlName: "companyName", value: true, diplayLabel: "Nombre del cliente" },
    { controlName: "gender", value: false, diplayLabel: "Sexo" },
    { controlName: "age", value: false, diplayLabel: "Edad" },
    { controlName: "phone", value: true, diplayLabel: "Número de teléfono" },
    { controlName: "email", value: true, diplayLabel: "Correo electrónico" },
    { controlName: "giroComercial", value: false, diplayLabel: "Giro comercial" },
    { controlName: "direccion", value: false, diplayLabel: "Dirección comercial" },
    { controlName: "fiscalAddress", value: false, diplayLabel: "Dirección fiscal" },
    { controlName: "city", value: false, diplayLabel: "Ciudad" },
    { controlName: "origin", value: false, diplayLabel: "Origen" },
    { controlName: "ejecutivo", value: false, diplayLabel: "Ejecutivo asignado" },
    { controlName: "vendor", value: true, diplayLabel: "Vendedor" },
    { controlName: "trainingDate", value: false, diplayLabel: "Fecha de capacitación " },
    { controlName: "createdAt", value: true, diplayLabel: "Fecha de creacion" },
    { controlName: "trainingHour", value: false, diplayLabel: "Hora de capacitación" },
    { controlName: "zoomLink", value: false, diplayLabel: "Link de Zoom" },
    { controlName: "needHelp", value: false, diplayLabel: "En que requiere ayuda" },
    { controlName: "plan", value: true, diplayLabel: "Plan contratado" },
    { controlName: "campaign", value: false, diplayLabel: "Campaña" },
    { controlName: "promotion", value: false, diplayLabel: "Promoción aplicada" },
    { controlName: "invoice", value: false, diplayLabel: "Requiere Factura" },
  ];
  public rowViewForm: FormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditRowsProspectTableComponent>,
    private formBuilder: FormBuilder
  ) {
    this.rowViewForm = this.formBuilder.group({
      name: [false],
      companyName: [false],
      gender: [false],
      age: [false],
      phone: [false],
      email: [false],
      giroComercial: [false],
      direccion: [false],
      fiscalAddress: [false],
      city: [false],
      origin: [false],
      ejecutivo: [false],
      vendor: [false],
      trainingDate: [false],
      createdAt: [false],
      trainingHour: [false],
      zoomLink: [false],
      needHelp: [false],
      plan: [false],
      campaign: [false],
      promotion: [false],
      invoice: [false],
    })
  }

  async ngOnInit() {
    var columnsOptions = JSON.parse(localStorage.getItem("newTColumns"))
    if (columnsOptions) {
      this.displayColumns = columnsOptions
    }
    this.displayColumns = this.displayColumns.sort((a, b) => a.value == b.value ? 0 : a.value ? -1 : 1)
    for await (const rowValue of this.displayColumns) {
      this.rowViewForm.patchValue({
        [rowValue.controlName]: rowValue.value
      })
    }
  }

  async submit() {
    const formValue = this.rowViewForm.value
    var keyForm = Object.keys(formValue);
    var valuesForm = Object.values(formValue);
    var existOne = valuesForm.filter((value) => value == true)
    if (existOne.length == 0) {
      return Swal.fire({
        title: 'Sin columnas seleccionadas',
        text: 'Debe haber al menos una columna que mostrar en la tabla',
        icon: 'error',
        confirmButtonText: 'Entendido'
      })
    }
    for (let i = 0; i < keyForm.length; i++) {
      const control = keyForm[i];
      this.displayColumns.map((columnRow) => {
        var findElement = this.displayColumns.filter((columaArray) => columaArray.controlName == control)[0]
        findElement.value = formValue[control]
        columnRow = findElement
      })
    }
    localStorage.setItem("newTColumns", JSON.stringify(this.displayColumns))
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-left',
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: 'colored-toast',
      },
      timerProgressBar: true
    });
    this.dialogRef.close({ change: true })

    await Toast.fire({
      icon: 'success',
      title: 'Configuracion guardada con exito',
    });
  }

}
