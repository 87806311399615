<section>
    <div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
    <div class="container">
        <div class="card">
            <div class="row header-section">
                <div class="col-md-5 text-left">
                    <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262"
                        width="140">
                </div>
                <div class="col-md-7 text-right header-buttons">
                    <button mat-button class="danger" printSectionId="printsection" ngxPrint
                        [useExistingCss]="true"><mat-icon>picture_as_pdf</mat-icon> Imprimir en PDF</button>
                    <button mat-button class="success" (click)="excel()"><mat-icon> insert_drive_file</mat-icon>
                        Exportar en Excel</button>
                </div>
            </div>
            <ng-container *ngIf="!loader">
                <div class="full-content">
                    <div class="row justify-content-center">
                        <div class="col-md-8 text-center">
                            <h2>{{data_source?.report}} </h2>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 text-center border-box">
                            <h3>Total de clientes</h3>
                            <h6>{{total_clients }} </h6>
                        </div>
                        <div class="col-md-4 text-center border-box">
                            <h4>Clientes equivalentes plata</h4>
                            <h6>{{total_equivalente | number:'1.0-0' }} </h6>
                        </div>
                        <div class="col-md-4 text-center border-box">
                            <h4>Total facturado</h4>
                            <h6> {{total_facturado | currency:'MXN'}} </h6>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 text-center border-box">
                            <h3>Total de asistentes</h3>
                            <h6>{{data_source.agents.length}} </h6>
                        </div>
                        <div class="col-md-4 text-center border-box">
                            <h4>Productividad por agente</h4>
                            <h6>{{ (total_equivalente / data_source.agents.length) | number:'1.2-2' }} </h6>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-md-11 nodata-card">
                            <table class="content-table">
                                <thead>
                                    <tr>
                                        <td>Nombre del asistente</td>
                                        <td>Tiempo trabajado</td>
                                        <td>Total horas trabajadas</td>
                                        <td>No. Clientes asignados</td>
                                        <td>Clientes equivalentes Plata</td>
                                        <td>Total facturado</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data_source.agents">
                                        <td>{{item.name}} </td>
                                        <td>{{item.timeWork || '0s'}} </td>
                                        <td>{{item.seconds_work / 3600 | number:"1.2-2"}} </td>
                                        <td>{{item.numbers_clients }} </td>
                                        <td>{{item.equivalentes | number:'1.0-0' }} </td>
                                        <td>{{item.total_invoiced | currency:'MXN' }} </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td>{{totalTime}} </td>
                                        <td>{{hours_work | number:"1.2-2"}} </td>
                                        <td>{{total_clients}} </td>
                                        <td>{{total_equivalente | number:'1.0-0' }} </td>
                                        <td>{{total_facturado | currency:'MXN' }} </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<div class="row" id="printsection" hidden>
    <div class="col-md-12 col-center">
        <nb-card>
            <nb-card-header style="  border-bottom: 2px solid #e26b0a;">
                <div class="row">
                    <div class="col-md-5 text-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262"
                            width="140">
                    </div>
                    <div class="col-md-7 text-right">
                        <ul style="list-style: none; padding-left: 1px;">
                            <li style="color: #e26b0a;">www.miasistente.net
                            </li>
                            <li>ventas&#64;miassitente.net
                            </li>
                            <li>800 288 02 68
                            </li>
                            <li>Cancún Quintana Roo México / Oficinas Centrales
                            </li>
                        </ul>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body style="margin-top: 1rem;">
                <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                        <h2>{{data_source?.report}} </h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 text-center border-box">
                        <h3>Total de clientes</h3>
                        <h6>{{total_clients }} </h6>
                    </div>
                    <div class="col-md-4 text-center border-box">
                        <h4>Clientes equivalentes plata</h4>
                        <h6>{{total_equivalente | number:'1.0-0' }} </h6>
                    </div>
                    <div class="col-md-4 text-center border-box">
                        <h4>Total facturado</h4>
                        <h6> {{total_facturado | currency:'MXN'}} </h6>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 text-center border-box">
                        <h3>Total de asistentes</h3>
                        <h6>{{data_source?.agents.length}} </h6>
                    </div>
                    <div class="col-md-4 text-center border-box">
                        <h4>Productividad por agente</h4>
                        <h6>{{ (total_equivalente / data_source?.agents.length) | number:'1.2-2' }} </h6>
                    </div>
                    <div class="col-md-4"></div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-md-11 nodata-card">
                        <table class="content-table-pdf">
                            <thead>
                                <tr>
                                    <td>Nombre del asistente</td>
                                    <td>Total horas trabajadas</td>
                                    <td>No. Clientes asignados</td>
                                    <td>Clientes equivalentes Plata</td>
                                    <td>Total facturado</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of data_source?.agents">
                                    <td>{{item.name}} </td>
                                    <td>{{item.timeWork || '0s'}} </td>
                                    <td>{{item.numbers_clients }} </td>
                                    <td>{{item.equivalentes | number:'1.0-0' }} </td>
                                    <td>{{item.total_invoiced | currency:'MXN' }} </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{{totalTime}} </td>
                                    <td>{{total_clients}} </td>
                                    <td>{{total_equivalente | number:'1.0-0' }} </td>
                                    <td>{{total_facturado | currency:'MXN' }} </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>