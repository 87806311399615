import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Company } from '../../models/company';
import { logClient } from '../../models/logs';
import { formatDate } from "@angular/common";
import { Prospect } from "src/app/models/prospects";

@Injectable({
  providedIn: "root",
})
export class CompanyService {

  public URIconnect = environment.URL_API;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private afStorage: AngularFireStorage,
  ) { }

  async getLogsDates() {
    try {
      var response = await this.afs.collection("dates-logs", ref => ref.orderBy('eventDate', 'desc')).get().toPromise();
      var logsDates = response.docs.map((actions) => {
        const data = actions.data() as any;
        const id = actions.id;
        return { id, ...data };
      });
      return logsDates
    } catch (error) {
      throw error
    }
  }

  async getAllCompanies() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Company[] }>(this.URIconnect + "/clients/get-active").toPromise()
      return response.data
    }
    catch (error) {
      console.log(error);
      throw error
    }
  }

  async getCompanyByUserUid(uid: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Company }>(this.URIconnect + "/clients/get/" + uid).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getCompaniesByUserName(userName) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Company[] }>(this.URIconnect + "/clients/get-by-username/" + userName).toPromise();
      return response.data
    }
    catch (error) {
      throw error
    }
  }

  async addAdditionalNumbers(array: any[], companyId: string, arrayPrefix: any[]) {
    try {
      await this.http.post<any>(this.URIconnect + '/clients/add-numbers', { data: array, companyId, prefixData: arrayPrefix }).toPromise();
    } catch (error) {
      throw error
    }
  }

  async getAdditionalsNumbers(compamyId: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: any }>(this.URIconnect + '/clients/get-additional-numbers/' + compamyId).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async createLog(companyId: string, asistentData: any) {
    try {
      await this.http.post<{ code: number, data: any }>(this.URIconnect + "/clients/add-log", { companyId: companyId, asesor: asistentData }).toPromise();
      return
    } catch (error) {
      throw error
    }
  }

  async getAllClients() {
    try {
      var clients = await this.http.get<{ code: number, data: Company[] }>(this.URIconnect + "/clients").toPromise();
      return clients.data;
    } catch (error) {
      throw error;
    }
  }

  async getCompanyPendings(company: string) {
    try {
      var clients = await this.http.post<{ code: number, data: any }>(this.URIconnect + "/pendings/get-byId", { id: company }).toPromise();
      return clients.data;
    } catch (error) {
      throw error;
    }
  }

  async getCompanyByPhoneNumber(phone: number) {
    try {
      var data = await this.afs.collection("empresas", (ref) => ref.where("telefono", "==", phone).where("activeInDashboard", "==", true)).get().toPromise();
      return data.empty ? null : data.docs[0].data();
    } catch (error) {
      throw error
    }
  }

  async findCompanyWhitDifferentNumber(phone: number) {
    try {
      var response = await this.http.get<{ data: Prospect }>(this.URIconnect + "/clients/by-other-num/" + phone).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async addCompany(company) {
    try {
      let newDate = company.contratoDate.setDate(company.contratoDate.getDate() + 1);
      company.contratoDate = formatDate(newDate, "yyyy-MM-dd", "en-US");
      var response = await this.http.post<{ code: number, message: string, data: any }>(this.URIconnect + "/clients/add", { data: company }).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async updateCompany(formValue: Prospect, id: string) {
    try {
      var response = await this.http.put<{ code: number, message: string, data: Company }>(this.URIconnect + "/clients/update/" + id, { prospectForm: formValue }).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async changeStatus(uid: string, status: boolean) {
    try {
      await this.http.put<any>(this.URIconnect + "/clients/status", { id: uid, status: status }).toPromise()
      return true
    } catch (error) {
      throw error;
    }
  }

  async getLogsCompany(uid: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: logClient[] }>(this.URIconnect + "/clients/get-logs/" + uid).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getLogsByRange(start_date: Date, end_date: Date, uid: string) {
    try {
      var time_start_date = start_date.getTime();
      var time_end_date = end_date.getTime();
      var response = await this.http.get<{ code: number, message: string, data: logClient[] }>(this.URIconnect + "/clients/get-logs-by-range/" + uid + "/" + time_start_date + "/" + time_end_date).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getTokens(id: string) {
    try {
      const response = await this.http.get<{ code: number, data: string[] }>(this.URIconnect + '/clients/tokens/' + id).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getTokensOfAgents(id: string) {
    try {
      const response = await this.http.get<{ code: number, data: string[] }>(this.URIconnect + '/clients/user-tokens/' + id).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

}
