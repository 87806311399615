import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { CompanyService } from 'src/app/services/company/company.service';
import { PlanService } from 'src/app/services/planService/plan.service';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/users/user.service';
import { Task } from '../../../models/task';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { UserData } from 'src/app/models/user';
import { logClient } from 'src/app/models/logs';
import { Plan } from 'src/app/models/plan';
import { CallsReportsService } from 'src/app/services/calls-reports/calls-reports.service';
import { MatDialogRef } from '@angular/material';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { Prospect } from 'src/app/models/prospects';
import { Call } from 'src/app/models/call';

@Component({
  selector: 'app-reporte-rentabilidad-modal',
  templateUrl: './reporte-rentabilidad-modal.component.html',
  styleUrls: ['./reporte-rentabilidad-modal.component.scss']
})
export class ReporteRentabilidadModalComponent implements OnInit {
  public addForm: FormGroup;
  public minDate: Date;
  public totalClients: number;
  public totalEjecutivos: number;
  public socialMediaEntries = []
  public array_empresas: any = [];
  public array_ejecutivos: any = [];
  public loading = false;
  public downloanding: string;
  public totalFactura: number = 0;
  public actualPlans: Plan[] = [];
  public extraTime: number;
  private companyList: Prospect[]
  private callReport: Call[] = []
  private callOutReport: Call[] = []

  constructor(
    public formBuilder: FormBuilder,
    private companyService: CompanyService,
    public ref: MatDialogRef<ReporteRentabilidadModalComponent>,
    private router: Router,
    private planService: PlanService,
    private userService: UserService,
    private reportService: ReporteService,
    private callService: CallsReportsService,
    private prospectService: ProspectsService
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      extension: ["", Validators.required],
    });
  }

  async getActiveProspects() {
    try {
      const reposponse = await this.prospectService.getActiveProspects()
      return reposponse.data
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al generar el reporte',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showConfirmButton: false
      })
    }
  }

  onSelectedStartDate(event) {
    var date = new Date(event);
    date.setDate(date.getDate() + 1);
    this.minDate = date;
  }

  async validateForm() {
    try {
      var form = this.addForm.value;
      if (form.start_date == "" || form.end_date == "") {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Seleccione una rango de fecha para crear el reporte',
          showConfirmButton: true,
        })
      } else {
        this.loading = true
        document.addEventListener('click', enableClick, true);
        this.actualPlans = await this.getPlans();
        this.companyList = await this.getActiveProspects()
        this.callReport = await this.getCalls(form.start_date, form.end_date)
        this.callOutReport = await this.getCallsOut(form.start_date, form.end_date)
        this.generate(form);
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al generar el reporte',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showConfirmButton: false
      })
    }
  }


  async generate(form) {
    var time_start_date = form.start_date.getTime();
    var time_end_date = form.end_date.getTime();

    var format_start_date = formatDate(new Date(time_start_date), 'dd/MM/yyyy', 'en-US');
    var format_end_date = formatDate(new Date(time_end_date), 'dd/MM/yyyy', 'en-US');

    var day_start = formatDate(new Date(time_start_date), 'dd', 'en-US');
    var month_start = formatDate(new Date(time_start_date), 'MMMM', 'es-MX');
    var year_start = formatDate(new Date(time_start_date), 'yyy', 'en-US');
    var start_date_txt = day_start + ' de ' + month_start + " de " + year_start;

    var day_end = formatDate(new Date(time_end_date), 'dd', 'en-US');
    var month_end = formatDate(new Date(time_end_date), 'MMMM', 'es-MX');
    var year_end = formatDate(new Date(time_end_date), 'yyy', 'en-US');
    var end_date_txt = day_end + ' de ' + month_end + " de " + year_end;

    await this.getCLients();
    if (!this.loading) {
      try {
        var data = {
          report: 'Reporte del ' + start_date_txt + ' al ' + end_date_txt,
          date: new Date().toString(),
          dateTime: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
          format_start_date: format_start_date,
          format_end_date: format_end_date,
          agents: this.array_ejecutivos
        }
        var response = await this.reportService.addProfitabilityReport(data)
        if (response.code == 200) {
          var reportID = response.data.insertedId;
          this.ref.close();
          document.removeEventListener('click', enableClick, true)
          this.router.navigate(['/reporte-rentabilidad-details/' + reportID])
        }
        else {
          throw response.message
        }
      } catch (error) {
        console.log(error);
        this.loading = false
        this.ref.close();
        document.removeEventListener('click', enableClick, true)
        if (error.status == 504) {
          return Swal.fire({
            icon: 'error',
            title: "Ocurrió un error externo al servidor",
            text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
          })
        } else {
          return Swal.fire({
            position: "center",
            icon: 'error',
            title: "Ocurrió un error al crear los datos",
            text: "Intente de nuevo más tarde",
            showConfirmButton: true,
          });
        }
      }
    }
  }

  async getCLients() {
    try {
      this.downloanding = "Consultando empresas..."
      var ejecutivos = await this.userService.getActiveUsers()
      await this.clientsByAgent(ejecutivos);
      this.loading = false
    } catch (error) {
      throw error
    }
  }

  private async getCalls(start: any, end: any) {
    try {
      start = new Date(start).getTime().toString()
      end = new Date(end).getTime().toString()
      const response = await this.callService.getAllCalls('%20', start, end)
      return response;
    } catch (error) {
      this.loading = false;
      this.ref.close();
      document.removeEventListener('click', enableClick, true)
      if (error.error.status == 504) {
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  private async getCallsOut(start: any, end: any) {
    try {
      start = new Date(start).getTime()
      end = new Date(end).getTime()
      const response = await this.callService.getOutgoinCalls('%20', start, end)
      return response;
    } catch (error) {
      this.loading = false;
      this.ref.close();
      document.removeEventListener('click', enableClick, true)
      if (error.error.status == 504) {
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  async unicValues(array: any) {
    var valueID: string[] = []
    array.forEach(element => {
      if (!valueID.includes(element.ejecutivo)) {
        valueID.push(element.ejecutivo)
      }
    });
    this.totalEjecutivos = valueID.length;
    return valueID

  }

  async clientsByAgent(agents: UserData[]) {
    try {
      var form = this.addForm.value

      for await (const ejecutivo of agents) {
        var tiempoTrabajado = 0
        if (ejecutivo.nombre == "Dapper Admin" || ejecutivo.nombre == "admin MIA") {
          continue
        }
        var empresas = this.companyList.filter((prospect) => prospect.ejecutivo.includes(ejecutivo.nombre))
        this.downloanding = "Buscando información del agente " + ejecutivo.nombre
        for await (const client of empresas) {
          this.downloanding = "Consultando llamadas de las empresas..."
          let phone = client.telefono.toString();
          let indexof52 = phone.indexOf("52");
          let phoneSearch = phone.includes("52") ? indexof52 > 0 ? `52${phone}` : phone : `52${phone}`;
          let prefix = client.prefix || "0001"

          let answeredEntriesCalls = this.callReport.filter(
            (call) => call.state == "ANSWERED" && call.extension == ejecutivo.extension.toString() && call.did == phoneSearch
          );
          let answeredSalientesCalls = this.callOutReport.filter(
            (call) => call.state == "ANSWERED" && call.extension == ejecutivo.extension.toString() && call.destino.startsWith(client.prefix)
          );

          if (client.additionalPrefix) {
            for await (const prefixData of client.additionalPrefix) {
              const element = prefixData.phone;
              let phoneAdd = element.toString();
              let indexof52Add = phoneAdd.indexOf("52");
              let phoneSearchAdd = phoneAdd.includes("52") ? indexof52Add > 0 ? `52${phoneAdd}` : phoneAdd : `52${phoneAdd}`;
              const additionalCalls = this.callReport.filter(
                (call) => call.state == "ANSWERED" && call.extension == ejecutivo.extension.toString() && call.did == phoneSearchAdd
              );
              answeredEntriesCalls = answeredEntriesCalls.concat(additionalCalls)
              const additionalOutgoinCalls = this.callOutReport.filter(
                (call) => call.state == "ANSWERED" && call.extension == ejecutivo.extension.toString() && call.destino.startsWith(prefixData.prefix)
              );
              answeredSalientesCalls = answeredSalientesCalls.concat(additionalOutgoinCalls)
            }
          }
          var llamadasEntrantes = await this.calculateTimeforCalls(answeredEntriesCalls)
          var llamadasSalientes = await this.calculateTimeforCalls(answeredSalientesCalls);
          var tiempollamadas = llamadasEntrantes + llamadasSalientes;

          let logClient = await this.companyService.getLogsByRange(form.start_date, form.end_date, client.uid);
          var userLogs = await this.logsOfAgent(logClient, ejecutivo);
          let socialMediaEntries = await this.countSMEntries(userLogs, client.tareasRecurrentes)
          var tiempoTareas = await this.calculateTimeforTask(socialMediaEntries, client.tareasRecurrentes)
          tiempoTareas = tiempoTareas * 60;
          this.downloanding = "Calculando horas trabajadas del agente..."
          tiempoTrabajado += tiempollamadas + tiempoTareas

          this.downloanding = "Calculando factura del cliente..."
          this.calculateAmount(client.plan, client.planPrice)
          var total_mails_arr = [];
          var total_citas_arr = [];
          var total_calls_arr = [];;
          logClient.forEach((element) => {
            var text = element.text;
            if (text.startsWith("Se envió")) {
              total_mails_arr.push(element);
            } else if (text.startsWith("Llamada atendida")) {
              total_calls_arr.push(element);
            } else if (text.startsWith("Cita agendada")) {
              total_citas_arr.push(element);
            }
          });

          var empresasUser = {
            empresa_id: client.uid,
            nombre_empresa: client.nombre,
            plan: client.plan,
            telefono: phoneSearch,
            total_Llamadas: answeredEntriesCalls.length + answeredEntriesCalls.length,
            tiempo_llamadas: this.secondsToHms(tiempollamadas),
            tiempo_tareas: this.secondsToHms(tiempoTareas),
            total_citas: total_citas_arr.length,
          }
          this.array_empresas.push(empresasUser);
        }
        var ejecutivos = {
          name: ejecutivo.nombre,
          timeWork: this.secondsToHms(tiempoTrabajado) || "0s",
          seconds_work: tiempoTrabajado || 0,
          numbers_clients: this.array_empresas.length,
          total_invoiced: this.totalFactura
        }
        this.downloanding = "Asociando ejecutivos con empresas"
        this.array_ejecutivos.push(ejecutivos)
        this.array_empresas = [];
        this.totalFactura = 0
        this.extraTime = 0
      }
    } catch (error) {
      this.loading = false
      this.ref.close();
      document.removeEventListener('click', enableClick, true)
      if (error.status == 504) {
        throw Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        throw Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  async logsOfAgent(logs: logClient[], userSelect: UserData) {
    try {
      var userLogs = []
      logs.forEach(element => {
        var whoDid = element.text.split("por ")[1]
        if (whoDid == userSelect.nombre) {
          userLogs.push(element);
        }
      });
      return userLogs
    } catch (error) {
      throw error
    }
  }

  additionalTimeForTasks(time: number) {
    this.extraTime += time;
  }

  async countSMEntries(entries: Array<any>, asingTask: Array<Task>) {
    try {
      asingTask.map(async (task: any) => {
        task["quantity"] = 0
        task["totalAll"] = 0
        entries.map(logs => {
          if (logs.text.startsWith(task.title)) {
            task.quantity += 1
            task.totalAll += logs.totalActions
          }
          if (logs.timeTask != undefined && logs.text.startsWith(task.title)) {
            //Se calcula el tiempo que le tomo realizar menos el tiempo real asignado a la tarea
            var time = logs.timeTask - task.minutes
            this.additionalTimeForTasks(time);
          }
        })
      })
      return asingTask
    } catch (error) {
      throw error;
    }
  }

  async calculateTimeforCalls(array: any) {
    var totalTime = 0;
    array.forEach(element => {
      totalTime += element.duration
    });
    return totalTime + this.extraTime
  }

  async calculateTimeforTask(array: any, asingTask: Array<Task>) {
    var totalTime = 0
    array.forEach(task => {
      var minutesObject = asingTask.filter(item => item.title == task.title);
      totalTime += (minutesObject[0].minutes * task.totalAll);
    });
    return totalTime
  }

  calculateAmount(plan: string, planPrice: any) {
    var userPlan = this.actualPlans.filter(Planes => Planes.title == plan);
    if (userPlan.length != 0) {
      if (planPrice != undefined && planPrice != 0) {
        this.totalFactura += parseInt(planPrice.toString());
      }
      else {
        this.totalFactura += userPlan[0].precio;
      }
    }
    else {
      this.totalFactura += 0;
    }
  }

  async getPlans() {
    try {
      var reports = await this.planService.getPlans();
      return reports.data
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar los planes',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showConfirmButton: false
      })
    }
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
    return hDisplay + mDisplay + sDisplay;
  }

}

function enableClick(e) {
  e.stopPropagation();
  e.preventDefault();
}
