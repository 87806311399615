import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts/contracts.service';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { PlanService } from 'src/app/services/planService/plan.service';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent implements OnInit {

  public loader = true
  public data_source: any
  public displayColumns = ['client', 'agent', 'completeData', 'callHours', "hourTask", "workHours", "plan", "planTime", "price", "vp", "vv", "percent"]
  public contractsList = []
  public planLists = []
  constructor(
    private reportService: ReporteService,
    private activeRoute: ActivatedRoute,
    private contractService: ContractsService,
    private planService: PlanService,
    private excelService: ExcelService
  ) { }

  async ngOnInit() {
    var id = this.activeRoute.snapshot.paramMap.get('id') ?? ''
    if (id != '') {
      this.contractsList = await this.getContracts()
      this.planLists = await this.getPlans()
      this.data_source = await this.findReport(id)
    }
    this.loader = false
  }

  async findReport(id: string) {
    try {
      var response = await this.reportService.findRankingReport(id)
      response.reportData.map((clientReport) => {
        clientReport.contractData = this.contractsList.filter((contract) => contract.clientId == clientReport.companyId)[0]
        clientReport.planData = this.planLists.filter((contract) => contract.title == clientReport.plan)[0]
        clientReport.usage = this.calculateUsedDates(clientReport.seconds_trabajo, parseInt(clientReport.planData.time))
        if (clientReport.seconds_trabajo != 0 && parseFloat((clientReport.seconds_trabajo / 3600).toFixed(2)) > 0) {
          clientReport.valueSale = clientReport.planData.precio / (clientReport.seconds_trabajo / 3600)
        } else {
          clientReport.valueSale = 0
        }
      })
      response.reportData.sort((a, b) => a.usage > b.usage ? -1 : 1)
      return response
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error'
      })
    }
  }

  private async getContracts() {
    try {
      var response = await this.contractService.get()
      return response.data
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error'
      })
    }
  }

  private async getPlans() {
    try {
      var response = await this.planService.getPlans()
      return response.data
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error'
      })
    }
  }

  calculateUsedDates(quouteTime: number, planTime: number) {
    try {
      var percentUsage = 0
      percentUsage = ((quouteTime / 3600) / planTime) * 100
      return percentUsage
    } catch (error) {
      console.log(error);
    }
  }

  async excel() {
    try {
      const headers = [
        "Nombre del cliente",
        "Ejecutivo asignado",
        "Fecha de Antigüedad",
        "Total de horas en llamadas",
        "Total de horas en tareas",
        "Total de horas trabajadas",
        "Plan",
        "Horas del plan",
        "Monto",
        "Valor pagado",
        "Valor venta",
        "% de Uso"
      ];
      var dataToPrint = []
      for await (const company of this.data_source.reportData) {
        var executives = ""
        for await (const executive of company.agent) {
          if (company.agent.length == 1) {
            executives = executive
          }
          else {
            executives = executive + ", " + executives
          }
        }
        var data = {
          "0": company.nombre_empresa,
          "1": executives,
          "2": formatDate(new Date(company.companyDate), 'dd/MM/yyyy', 'en-US'),
          "3": parseFloat((company.seconds_llamadas / 3600).toFixed(2)),
          "4": parseFloat((company.seconds_tareas / 3600).toFixed(2)),
          "5": parseFloat((company.seconds_trabajo / 3600).toFixed(2)),
          "6": company.plan,
          "7": company.planData.time + " hrs",
          "8": company.planData.precio,
          "9": company.planData.precio / company.planData.time,
          "11": company.valueSale,
          "12": (company.usage).toFixed(2) + "%",
        }
        dataToPrint.push(data)
      }
      const fileName = "Reporte Ranking del " + formatDate(this.data_source.startDate, 'dd MMMM yyyy', 'es-MX') + ' al ' + formatDate(this.data_source.endDate, 'dd MMMM yyyy', 'es-MX')
      await this.excelService._createReport(dataToPrint, headers, fileName, "RENTABILIDAD POR CLIENTE", ["-"], [5, 8, 10, 11], [9, 10, 11])
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al descargar el excel'
      })
    }
  }

}
