import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { GenerateGeneralReportComponent } from '../generate-general-report/generate-general-report.component';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';
import { UserService } from 'src/app/services/users/user.service';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { UserData } from 'src/app/models/user';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';

@Component({
  selector: 'app-general-reports',
  templateUrl: './general-reports.component.html',
  styleUrls: ['./general-reports.component.scss']
})
export class GeneralReportsComponent implements OnInit {

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    edit: {
      editButtonContent: '<span>Ver detalles</span>',
    },
    delete: {
      deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
      report: {
        title: 'Reporte',
      },
      clientName: {
        title: 'Cliente',
      },
      date: {
        title: 'Fecha de creación',
      },
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
  };
  public data_source = [];
  public loader = false
  public userData!: UserData;
  private clientsUser = []

  constructor(
    private dialogService: MatDialog,
    private reports_service: ReporteService,
    private router: Router,
    private userService: UserService,
    private authservice: AuthService,
    private clientService: ProspectsService
  ) { }

  async ngOnInit() {
    await this.getAllData();
  }

  async getAllData() {
    this.loader = true
    this.userData = await this.getUsarData()
    this.clientsUser = await this.getUserClients(this.userData.nombre)
    this.data_source = await this.getAllReports()
    this.loader = false
  }

  private async getUsarData() {
    try {
      let currentUserId = this.authservice.currentuser().uid;
      return await this.userService.getUserById(currentUserId);
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al consultar la informacion del usuario',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getUserClients(name: string) {
    try {
      var response = await this.clientService.getActiveProspects()
      var prospects = response.data
      if (this.userData.tipo != 'administrador') {
        var result = prospects.filter((prospect) =>
          prospect.assistant.includes(name) || prospect.ejecutivo.includes(name)
        )
        return result.map(prospect => prospect.uid)
      }
      return prospects.map(prospect => prospect.uid)
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al consultar la informacion del usuario',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getAllReports() {
    try {
      var reports = await this.reports_service.getGeneralClientReport();
      reports.map((element: any) => {
        element.date = formatDate(element.date._seconds * 1000, 'dd-MM-yyyy', 'es-MX');
      });
      return reports.filter((report) => this.clientsUser.includes(report.clientID))
    } catch (error) {
      await Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error al consultar los reportes',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  openDetails(data) {
    this.router.navigate(['reporte-general/', data.data.id])

  }
  createNew() {
    this.dialogService.open(GenerateGeneralReportComponent, { height: '50vh' });
  }

  async delete(data) {
    var result = await Swal.fire({
      title: '¿Seguro que quiere eliminar este reporte?',
      text: "Esta acción no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    })
    if (result.value) {
      await this.reports_service.deleteReport(data.data.id)
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Datos eliminados',
        showConfirmButton: false,
        timer: 1000
      })
      await this.getAllData();
    }
  }

}
