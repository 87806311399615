import { Component, OnInit } from '@angular/core';
import { ReportTableConfig } from './table-config'
import { MatDialog, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import Swal from 'sweetalert2';
import { Prospect } from 'src/app/models/prospects';
import { logClient } from 'src/app/models/logs';
import { Task } from 'src/app/models/task';
import { CallsReportsService } from 'src/app/services/calls-reports/calls-reports.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { ContractsService } from 'src/app/services/contracts/contracts.service';
import { formatDate } from '@angular/common';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-ranking',
  templateUrl: './report-ranking.component.html',
  styleUrls: ['./report-ranking.component.scss']
})
export class ReportRankingComponent implements OnInit {

  public data_source = []
  public settings = ReportTableConfig
  public reportForm: FormGroup
  public filteredOptions: any;
  public clients: Prospect[];
  public minDate: Date;
  public extraTime: number = 0;
  public loading: boolean;
  private callReportList: any;
  private callOutReportList: any;
  constructor(
    public dialog: MatDialog,
    private formbuilder: FormBuilder,
    private prospectService: ProspectsService,
    private callService: CallsReportsService,
    private companyService: CompanyService,
    private contractService: ContractsService,
    private reportService: ReporteService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.reportForm = this.formbuilder.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required]
    })
    this.clients = await this.getClients()
    this.data_source = await this.getReports()
  }

  private async getClients() {
    try {
      var response = await this.prospectService.getActiveProspects()
      return response.data
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al consultar los clientes'
      })
    }
  }

  private async getReports() {
    try {
      var response = await this.reportService.getRankingReports()
      response.data.map((info) => {
        info.report = 'Reporte del ' + new Date(info.startDate).toLocaleDateString() + ' al ' + new Date(info.endDate).toLocaleDateString()
        info.date = new Date(info.date).toLocaleDateString()
      })
      return response.data
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al consultar los clientes'
      })
    }
  }

  onSelectedStartDate(event) {
    var date = new Date(event);
    date.setDate(date.getDate() + 1);
    this.minDate = date;
  }

  createNew(dialog: any) {
    this.dialog.open(dialog, { height: '40vh' })
  }

  async validateForm() {
    if (this.reportForm.invalid) {
      return Swal.fire({
        icon: 'error',
        title: 'Favor de seleccionar las fechas correspondientes'
      })
    }
    const formValue = this.reportForm.value
    var reportData = await this.generateReport(formValue)
    var buildReport = {
      reportData,
      startDate: new Date(formValue.start_date),
      endDate: new Date(formValue.end_date)
    }
    this.loading = false
    await this.saveReport(buildReport)
  }

  async saveReport(newData: any) {
    try {
      var response = await this.reportService.createRankingReport(newData)
      this.dialog.closeAll()
      this.router.navigate(['/reporte-ranking/' + response])
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: 'error',
        title: "Ocurrió un error al crear los datos",
        text: "Intente de nuevo más tarde",
        showConfirmButton: true,
      });
    }
  }

  openDetails(data: any) {
    this.router.navigate(['/reporte-ranking/' + data.data._id])
  }

  async delete(data: any) {
    try {
      var result = await Swal.fire({
        title: 'Desea eliminar este reporte?',
        icon: 'warning',
        text: 'Esta accion es irreversible, se eliminara todo el reporte',
        showCancelButton: true
      })
      if (result) {
        await this.reportService.deleteRankingReport(data.data._id)
        await Swal.fire({
          title: 'Información eliminada con exito',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        })
        this.ngOnInit()
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: 'error',
        title: "Ocurrió un error al crear los datos",
        text: "Intente de nuevo más tarde"
      });
    }
  }

  async getAllCallsForReport(start_date: number, end_date: number) {
    try {
      var response = await this.callService.getAllCalls('%20', start_date.toString(), end_date.toString());
      return response;
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.dialog.closeAll();
      if (error.status == 504) {
        return Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        return Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  async getCallsOut(start: string, end: string) {
    try {
      const response = await this.callService.getCallSalientes('%', start, end)
      return response;
    } catch (error) {
      this.loading = false;
      this.dialog.closeAll();
      if (error.error.status == 504) {
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  private async generateReport(dataForm: any) {
    try {
      this.loading = true;
      var arrayOfCompanies = []
      var empresas = await this.prospectService.getActiveProspects();
      var contractList = await this.getContracts()
      var time_start_date = dataForm.start_date.getTime();
      var time_end_date = dataForm.end_date.getTime();

      var format_start_date = formatDate(new Date(time_start_date), 'dd/MM/yyyy', 'en-US');
      var format_end_date = formatDate(new Date(time_end_date), 'dd/MM/yyyy', 'en-US');

      var total_mails_arr = [];
      var total_citas_arr = [];
      var total_calls_arr = [];

      this.callReportList = await this.getAllCallsForReport(time_start_date, time_end_date)
      this.callOutReportList = await this.getCallsOut(format_start_date, format_end_date)
      for await (const empresa of empresas.data) {
        let phone = empresa.telefono.toString();
        let indexof52 = phone.indexOf("52");
        let phoneSearch = phone.includes("52") ? indexof52 > 0 ? `52${phone}` : phone : `52${phone}`;
        let prefix = empresa.prefix || "0001"
        const contractInfo = contractList.filter((contract) => contract.clientId == empresa.uid)[0]
        var startDateContract = contractInfo.nextRenovationDate

        var apiCall = this.callReportList.filter((call) => call.did == phoneSearch);
        if (empresa.additionalPrefix != undefined) {
          for await (const iterator of empresa.additionalPrefix) {
            const element = iterator.phone;
            let phoneAdd = element.toString();
            let indexof52Add = phoneAdd.indexOf("52");
            let phoneSearchAdd = phoneAdd.includes("52") ? indexof52Add > 0 ? `52${phoneAdd}` : phoneAdd : `52${phoneAdd}`;
            let incomingCallsAdd = this.callReportList.filter((call) => call.did == phoneSearchAdd);
            apiCall = apiCall.concat(incomingCallsAdd)
          }
        }

        var outGoingCalls = this.callOutReportList.filter(
          (call) => call.destino.startsWith(prefix)
        );
        if (empresa.additionalPrefix) {
          for await (const prefixData of empresa.additionalPrefix) {
            if (prefixData.prefix != '') {
              var additionalOutgoinCalls = this.callOutReportList.filter(
                (call) => call.destino.startsWith(prefixData.prefix)
              );
              outGoingCalls = outGoingCalls.concat(additionalOutgoinCalls)
            }
          }
        }
        let answeredEntriesCalls = apiCall.filter((call) => call.state == "ANSWERED");
        let answeredSalientesCalls = outGoingCalls.filter((call) => call.state == "ANSWERED" || call.state == "NO ANSWER");
        var timeInQuouteCall = await this.getCallsOfQuote(apiCall, startDateContract, time_end_date)
        var timeOutQuouteCall = await this.getCallsOfQuote(outGoingCalls, startDateContract, time_end_date)

        var llamadasEntrantes = await this.calculateTimeforCalls(answeredEntriesCalls);
        var llamadasSalientes = await this.calculateTimeforCalls(answeredSalientesCalls);
        var tiempollamadas = llamadasEntrantes + llamadasSalientes;

        let logsCLient = await this.companyService.getLogsByRange(dataForm.start_date, dataForm.end_date, empresa.uid);
        const timeTaskQuoute = await this.countLogsQuoute(logsCLient, startDateContract, time_end_date, empresa.tareasRecurrentes)
        let socialMediaEntries = await this.countSMEntries(logsCLient, empresa.tareasRecurrentes);

        var tiempoTareas = await this.calculateTimeforTask(socialMediaEntries, empresa.tareasRecurrentes)
        tiempoTareas = tiempoTareas * 60;
        var tiempoTrabajado = tiempollamadas + tiempoTareas

        logsCLient.forEach((element) => {
          var text = element.text;
          if (text.startsWith("Se envió")) {
            total_mails_arr.push(element);
          } else if (text.startsWith("Llamada atendida")) {
            total_calls_arr.push(element);
          } else if (text.startsWith("Cita agendada")) {
            total_citas_arr.push(element);
          }
        });
        var empresasUser: any = {
          nombre_empresa: empresa.nombre,
          plan: empresa.plan,
          companyId: empresa.uid,
          companyDate: empresa.completeData,
          tiempo_llamadas: this.secondsToHms(tiempollamadas) || "0s",
          seconds_llamadas: tiempollamadas,
          tiempo_tareas: this.secondsToHms(tiempoTareas) || "0s",
          seconds_tareas: tiempoTareas,
          total_trabajo: this.secondsToHms(tiempoTrabajado) || "0s",
          seconds_trabajo: tiempoTrabajado,
          agent: empresa.ejecutivo,
          quoteTime: timeInQuouteCall + timeOutQuouteCall + (timeTaskQuoute * 60),
        }
        if (empresasUser.total_Llamadas != 0 || empresasUser.total_tareas != 0) {
          arrayOfCompanies.push(empresasUser);
        }
        this.extraTime = 0
      }
      return arrayOfCompanies
    } catch (error) {
      console.log(error);
      return Swal.fire({
        title: 'Ocurrió un erro al crear el reporte',
        icon: 'error'
      })
    }
  }

  async getContracts() {
    try {
      var response = await this.contractService.get()
      return response.data
    } catch (error) {
      console.log(error);

    }
  }

  additionalTimeForTasks(time: number) {
    this.extraTime += time;
  }

  async countSMEntries(entries: Array<logClient>, asingTask: Array<Task>) {
    try {
      asingTask.map(async (task) => {
        task.quantity = 0
        task.totalAll = 0
        entries.map(logs => {
          if (logs.text.startsWith(task.title)) {
            task.quantity += 1
            task.totalAll += logs.totalActions
          }
          if (logs.timeTask != undefined && logs.text.startsWith(task.title)) {
            //Se calcula el tiempo que le tomo realizar menos el tiempo real asignado a la tarea
            var time = logs.timeTask - task.minutes
            this.additionalTimeForTasks(time);
          }
        })
      })
      return asingTask
    } catch (error) {
      throw error;
    }
  }

  async calculateTimeforCalls(array: any) {
    var totalTime = 0;
    array.forEach(element => {
      totalTime += element.duration
    });
    return totalTime
  }

  async getCallsOfQuote(callsApi: any[], contractDate: number, reportDate: number) {
    callsApi.map((call) => {
      const [day, month, year] = call.calldate.split('/')
      call.date = new Date().setFullYear(year.split(' ')[0], month, day)
    })
    var contractReportDate = new Date(new Date(reportDate).setDate(new Date(contractDate).getDate())).setHours(0, 0, 0, 0)
    var totalCall = callsApi.filter((call) => call.date >= contractReportDate && call.state == 'ANSWERED').reduce((total, value) => total + value.duration, 0)
    return totalCall
  }

  async countLogsQuoute(logsCompany, contractDate: number, reportDate: number, asingTask: any) {
    logsCompany.map((log) => {
      log.date = new Date(log.date._seconds * 1000)
    })
    var contractReportDate = new Date(new Date(reportDate).setDate(new Date(contractDate).getDate())).setHours(0, 0, 0, 0)
    var filterLogsContract = logsCompany.filter((log) => log.date >= new Date(contractReportDate))
    var extraTime = 0
    asingTask.map(async (task) => {
      task.quantity = 0
      task.totalAll = 0
      filterLogsContract.map(logs => {
        if (logs.text.startsWith(task.title)) {
          task.quantity += 1
          task.totalAll += logs.totalActions
        }
        if (logs.timeTask != undefined && logs.text.startsWith(task.title)) {
          //Se calcula el tiempo que le tomo realizar menos el tiempo real asignado a la tarea
          var time = logs.timeTask - task.minutes
          extraTime += time;
        }
      })
    })
    var totalTimeTask = asingTask.reduce((total, task) => total + (task.totalAll * task.minutes), 0)
    return totalTimeTask + extraTime
  }

  async calculateTimeforTask(SMEntries: Task[], asingTask: Array<Task>) {
    var totalTime = 0
    SMEntries.forEach((task: any) => {
      var minutesObject = asingTask.filter(item => item.title == task.title);
      totalTime += (minutesObject[0].minutes * task.totalAll);
    });
    return totalTime + this.extraTime
  }

  calculateAllTimeOfTasks(SMEntries: Task[]) {
    var totalTime = 0
    SMEntries.forEach((task: any) => {
      totalTime += task.totalAll
    });
    return totalTime
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
    return hDisplay + mDisplay + sDisplay;
  }


}
