export const ReportTableConfig = {
    actions: {
        columnTitle: 'Acciones',
        add: false,
        edit: true,
        delete: true,
        position: 'right'
    },
    pager: {
        display: true,
        perPage: 20,
    },
    edit: {
        editButtonContent: '<span>Ver detalles</span>',
    },
    delete: {
        deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
        report: {
            title: 'Reporte',
        },
        date: {
            title: 'Fecha de creación',
        },
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
};